import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readTrasanctionInvoicePaymentReport(){
        var query = `query($startDate:String!, $endDate:String!, $filterDate:String!) {
            GetTransactionInvoicePaymentReport(start_date:$startDate, end_date:$endDate, filter_date:$filterDate){
                summary{
                    quantity_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                    total_payment_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                }
                detail{
                    inv_payment_id
                    inv_payment_number
                    inv_payment_type
                    inv_payment_date
                    status
                    customer_id
                    customer_name
                    project_type
                    payment_method
                    bank
                    branch
                    account_number
                    account_name
                    round_up
                    delivery_fee
                    admin_fee
                    other_fee
                    invoice_id
                    invoice_number
                    invoice_numbers
                    total
                    paid
                    total_payment
                    left
                    notes
                }
            }
        }`;
        return query;
    }

    async getTransactionInvoicePaymentReport(variables) {
        var query = gql`query($startDate:String!, $endDate:String!, $filterDate:String!) {
            GetTransactionInvoicePaymentReport(start_date:$startDate, end_date:$endDate, filter_date:$filterDate){
                summary{
                    quantity_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                    total_payment_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                }
                detail{
                    inv_payment_id
                    inv_payment_number
                    inv_payment_type
                    inv_payment_date
                    status
                    customer_id
                    customer_name
                    payment_method
                    bank
                    branch
                    account_number
                    account_name
                    round_up
                    delivery_fee
                    admin_fee
                    other_fee
                    invoice_id
                    invoice_number
                    invoice_numbers
                    total
                    paid
                    total_payment
                    left
                    notes
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetTransactionInvoicePaymentReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    status: data[i].status,
                    inv_payment_number: data[i].inv_payment_number,
                    invoice_number: data[i].invoice_number,
                    inv_payment_date: kendo.toString(kendo.parseDate(data[i].inv_payment_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    inv_payment_type: data[i].inv_payment_type,
                    customer_name: data[i].customer_name,
                    total: kendo.toString(data[i].total, "n2"),
                    payment_method: data[i].payment_method,
                    bank: data[i].bank,
                    account_name: data[i].account_name,
                    notes: data[i].notes,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    transactionInvoicePaymentReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS

        ];
        return size;
    }

    transactionInvoicePaymentReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'TRANSAKSI INVOICE PAYMENT REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO. PAYMENT INVOICE',
            colC : 'NO. BUKTI',
            colD : 'TANGGAL',
            colE : 'TIPE INVOICE PAYMENT',
            colF : 'CUSTOMER',
            colG : 'TOTAL',
            colH : 'METODE PEMBAYARAN',
            colI : 'BANK',
            colJ : 'NAMA AKUN',
            colK : 'CATATAN',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].inv_payment_number,
                    colC : data[i].invoice_number,
                    colD : (data[i].inv_payment_date == null || data[i].inv_payment_date == '') ? '-' : { v: data[i].inv_payment_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colE : data[i].inv_payment_type,
                    colF : data[i].customer_name,
                    colG : { v: data[i].total_payment, t: 'n' , z: numberFormat },
                    colH : data[i].payment_method,
                    colI : data[i].bank,
                    colJ : data[i].account_name,
                    colK : data[i].notes,
                    // colO : { v: data[i].quantity, t: 'n' , z: numberFormat 
                };
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }
}

export default new PurchasingInvoiceReportService();