import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';


class TrialBalanceReportService {
    readTrialBalanceQuery(){
        var query = `
            query ($startDate: DateTime!, $endDate: DateTime!, $setting: [NewBalanceSettingDetail!]!, $status: String) {
                GetTrialBalanceUnnested(StartDate:$startDate, EndDate:$endDate, BalanceSetting:$setting, Status:$status){
                    head_name
                    group_name
                    chart_of_account_id
                    chart_of_account_name
                    initial_balance
                    credit
                    debit
                    balance
                }
            }`;
        return query;
    }

    async getTrialBalanceQuery(variables){
        var query = gql`
            query ($startDate: DateTime!, $endDate: DateTime!, $setting: [NewBalanceSettingDetail!]!, $status: String) {
                GetTrialBalanceUnnested(StartDate:$startDate, EndDate:$endDate, BalanceSetting:$setting, Status:$status){
                    head_name
                    group_name
                    chart_of_account_id
                    chart_of_account_name
                    initial_balance
                    credit
                    debit
                    balance
                }
            }`;
    
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTrialBalanceUnnested;
    }

    async getBalanceSettingQuery(){
        var query = gql`query{
            GetBalanceSetting{
                id
                name
              }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetBalanceSetting != null){
            for (let i = 0; i < result.data.GetBalanceSetting.length; i++) {
                var str = { value:result.data.GetBalanceSetting[i].id, 
                            label:result.data.GetBalanceSetting[i].name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getBalanceSettingData(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetBalanceSetting(ID: $id){
                balance_setting_detail_v{
                    id
                    head
                    coa_id
                    coa_group
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        var data = result.data.GetBalanceSetting[0].balance_setting_detail_v;
        
        if(data == null){
            return [];
        }else{
            var objData = globalfunc.objectToArrayConverter(result.data.GetBalanceSetting[0].balance_setting_detail_v, "Accounting-SettingBalance");
            return objData;
        }
    }
    
    trialBalanceItemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    head_name: data[i].head_name,
                    group_name: data[i].group_name,                    
                    chart_of_account_id: data[i].chart_of_account_id,
                    chart_of_account_name: data[i].chart_of_account_name,
                    initial_balance: kendo.toString(data[i].initial_balance, "n2"),
                    credit: kendo.toString(data[i].credit, "n2"),
                    debit: kendo.toString(data[i].debit, "n2"),
                    balance: kendo.toString(data[i].balance, "n2")
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    trialBalanceReportExcelHeaderSize(){
        var size = [
            {width: 15}, //colA
            {width: 25}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 25}, //colF
        ];

        return size;
    }

    trialBalanceReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 7;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'TRIAL BALANCE',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var row4 = {colA : {f: '=C' + (lastIndex + 2).toString(), t:'n', z: numberFormat}};
        arrayObject.push(row4);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {colA : 'COA PERK',
                    colB : 'NAMA PERKIRAAN',
                    colC : 'SALDO AWAL',
                    colD : 'DEBET',
                    colE : 'KREDIT',
                    colF : 'SALDO AKHIR'};
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {colA : data[i].chart_of_account_id,
                    colB : data[i].chart_of_account_name,
                    colC : { v: data[i].initial_balance, t: 'n' , z: numberFormat},
                    colD : { v: data[i].debit, t: 'n' , z: numberFormat},
                    colE : { v: data[i].credit, t: 'n' , z: numberFormat},
                    colF : { v: data[i].balance, t: 'n' , z: numberFormat}};
                arrayObject.push(row);
            }
        }

        arrayObject.push(spaceRow);

        var row = { colA : '',
                    colB : 'TOTAL',
                    colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},
                    colD : {f: '=SUM(D'+ startIndex.toString() +':D'+ lastIndex.toString() +')' , t:'n', z: numberFormat},
                    colE : {f: '=SUM(E'+ startIndex.toString() +':E'+ lastIndex.toString() +')' , t:'n', z: numberFormat},
                    colF : {f: '=SUM(F'+ startIndex.toString() +':F'+ lastIndex.toString() +')' , t:'n', z: numberFormat}};
        arrayObject.push(row);

        return arrayObject;
    }

    trialBalanceReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 30}, //colB
            {width: 20}, //colC
        ];

        return size;
    }

    

}


export default new TrialBalanceReportService();