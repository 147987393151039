import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readTrasanctionInvoiceReport(){
        var query = `
            query($startDate:String!, $endDate:String!, $statusPayment:[String], $status:[String]) {
                GetTransactionInvoiceReport(start_date:$startDate, end_date:$endDate, statusPayment:$statusPayment, status:$status){
                    summary{
                        quantity_per_status{
                            new
                            sent
                            delete
                        }
                        total_price_per_status{
                            new
                            sent
                            delete
                        }
                    }
                    detail {
                        created_at
                        created_by
                        last_update
                        invoice_id
                        invoice_type
                        invoice_number
                        invoice_date
                        due_date
                        status
                        purchase_order_id
                        purchase_order_number
                        sp_number
                        project_type
                        customer_id
                        customer_name
                        delivery_order_number
                        tax_invoice
                        tax_percentage
                        notes
                        total
                        total_deposit
                        tax_invoice_deposit
                        item_id
                        product_code
                        item_name
                        uom
                        price_per_unit
                        price
                        price_discount
                        ppn_out
                        vat_per_unit
                        vat
                        fulfilled_quantity
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount_plus
                        barcode
                        type_detail_id
                        sum_total_price_discount
                        sum_total_vat
                        sum_total_ppn_out
                        sum_total_payment
                        status_payment
                        outstanding_amount
                        total_conv_amount
                        conv_amount
                    }
                }
            }
        `;
        return query;
    }

    async getTransactionInvoiceReport(variables) {
        var query = gql`
            query($startDate:String!, $endDate:String!, $statusPayment:[String], $status:[String]) {
                GetTransactionInvoiceReport(start_date:$startDate, end_date:$endDate, statusPayment:$statusPayment, status:$status){
                    summary{
                        quantity_per_status{
                            new
                            sent
                            delete
                        }
                        total_price_per_status{
                            new
                            sent
                            delete
                        }
                    }
                    detail {
                        created_at
                        created_by
                        last_update
                        invoice_id
                        invoice_type
                        invoice_number
                        invoice_date
                        due_date
                        status
                        purchase_order_id
                        purchase_order_number
                        sp_number
                        project_type
                        customer_id
                        customer_name
                        delivery_order_number
                        tax_invoice
                        tax_percentage
                        notes
                        total
                        total_deposit
                        tax_invoice_deposit
                        item_id
                        product_code
                        item_name
                        uom
                        price_per_unit
                        price
                        price_discount
                        ppn_out
                        vat_per_unit
                        vat
                        fulfilled_quantity
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount_plus
                        barcode
                        type_detail_id
                        sum_total_price_discount
                        sum_total_vat
                        sum_total_ppn_out
                        sum_total_payment
                        status_payment
                        outstanding_amount
                        total_conv_amount
                        conv_amount
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetTransactionInvoiceReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    invoice_date: kendo.toString(kendo.parseDate(data[i].invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    invoice_number: data[i].invoice_number,
                    delivery_order_number: data[i].delivery_order_number,
                    tax_invoice: data[i].tax_invoice,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    fulfilled_quantity: data[i].fulfilled_quantity,
                    total_conv_amount: data[i].total_conv_amount,
                    price: kendo.toString(data[i].price, "n2"),
                    price_discount: kendo.toString(data[i].price_discount, "n2"),
                    ppn_out: kendo.toString(data[i].ppn_out, "n2"),
                    vat: kendo.toString(data[i].vat, "n2"),
                    customer_name: data[i].customer_name,
                    due_date: kendo.toString(kendo.parseDate(data[i].due_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    purchase_order_number: data[i].purchase_order_number,
                    outstanding_amount: data[i].outstanding_amount,
                    status_payment: data[i].status_payment,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    transactionInvoiceReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 30}, //colB
            {width: 30}, //colC
            {width: 30}, //colD
            {width: 40}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 30}, //colL
            {width: 20}, //colM
            {width: 30}, //colN
            {width: 30}, //colO
            {width: 20}, //colP
        ];
        return size;
    }

    transactionInvoiceReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.invoice_id) && uniques.push(t.invoice_id));

        var arrayObject = [];
        var spaceRow = {colA : null};
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'BUKU PENJUALAN PIPA & FITTING',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'TANGGAL',
            colB : 'NO. FAKTUR',
            colC : 'NO. SJ',
            colD : 'NO. FAKTUR PAJAK',
            colE : 'NAMA BARANG',
            colF : 'QUANTITY',
            colG : 'QUANTITY SATUAN',
            colH : 'HARGA',
            colI : 'DPP',
            colJ : 'PPN OUT',
            colK : 'PIUTANG',
            colL : 'KETERANGAN',
            colM : 'JATUH TEMPO',
            colN : 'NO. PO',
            colO : 'OUTSTANDING',
            colP : 'STATUS PAYMENT'
        };
        arrayObject.push(row6);

        if(data != null){
            var totalDPP = 0;
            var totalPPNOut = 0;
            var totalPiutang = 0;

            for (let k= 0; k < uniques.length; k++) {                
                var detail = data.filter(c => c.invoice_id == uniques[k])
                var totalQty = 0;
                var totalConvQty = 0;

                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : (detail[i].invoice_date == null || detail[i].invoice_date == '') ? '-' : { v: detail[i].invoice_date, t: 'd', z: 'DD-MMM-YYYY' },
                        colB : detail[i].invoice_number,
                        colC : detail[i].delivery_order_number,
                        colD : detail[i].tax_invoice,
                        colE : detail[i].item_name,
                        colF : detail[i].fulfilled_quantity,
                        colG : detail[i].total_conv_amount,
                        colH : { v: detail[i].price, t: 'n' , z: numberFormat },
                        colI : { v: detail[i].price_discount, t: 'n' , z: numberFormat },
                        colJ : { v: parseInt(detail[i].ppn_out), t: 'n' , z: numberFormat },
                        colK : { v: parseInt(detail[i].vat), t: 'n' , z: numberFormat },
                        colL : detail[i].customer_name,
                        colM : (detail[i].due_date == null || detail[i].due_date == '') ? '-' : { v: detail[i].due_date, t: 'd', z: 'DD-MMM-YYYY' },
                        colN : detail[i].purchase_order_number,
                        colO : { v: Math.round(detail[i].outstanding_amount), t: 'n' , z: numberFormat },
                        colP : detail[i].status_payment,
                    };                
                    arrayObject.push(row);

                    totalQty += detail[i].fulfilled_quantity;
                    totalConvQty += detail[i].total_conv_amount;
                }
    
                var sumData = data.find(c => c.invoice_id == uniques[k])
                var rowTotal = {
                    colA : (sumData.invoice_date == null || sumData.invoice_date == '') ? '-' : { v: sumData.invoice_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colB : sumData.invoice_number,
                    colC : sumData.delivery_order_number,
                    colD : sumData.tax_invoice,
                    colE : '',
                    colF : totalQty,
                    colG : totalConvQty,
                    colH : '-',
                    colI : { v: sumData.sum_total_price_discount, t: 'n' , z: numberFormat },
                    colJ : { v: parseInt(sumData.sum_total_ppn_out), t: 'n' , z: numberFormat },
                    colK : { v: parseInt(sumData.sum_total_vat), t: 'n' , z: numberFormat },
                    colL : sumData.customer_name,
                    colM : (sumData.due_date == null || sumData.due_date == '') ? '-' : { v: sumData.due_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colN : sumData.purchase_order_number,
                    colO : { v: Math.round(sumData.outstanding_amount), t: 'n' , z: numberFormat },
                    colP : sumData.status_payment,
                }; 
                
                //declare total
                totalDPP = totalDPP + sumData.sum_total_price_discount;
                totalPPNOut = totalPPNOut + parseInt(sumData.sum_total_ppn_out);
                totalPiutang = totalPiutang + parseInt(sumData.sum_total_vat);

                arrayObject.push(rowTotal);
                
                arrayObject.push(spaceRow);
            }

            //set grand total
            arrayObject.push(spaceRow);
            var rowGrandTotal = {
                colA : null,
                colB : null,
                colC : null,
                colD : null,
                colE : null,
                colF : null,
                colG : null,
                colH : 'TOTAL',
                colI : { v: totalDPP, t: 'n' , z: numberFormat },
                colJ : { v: totalPPNOut, t: 'n' , z: numberFormat },
                colK : { v: totalPiutang, t: 'n' , z: numberFormat },
                colL : null,
                colM : null,
                colN : null,
                colO : null,
                colP : null
            };
            arrayObject.push(rowGrandTotal);
        }

        return arrayObject;
    }
}

export default new PurchasingInvoiceReportService();