import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class SalesReportService {    
    readSalesReport(){
        var query = `
            query (
                $startDate:String!,$endDate:String!, 
                $typeDetail:String, 
                $itemId:String,
                $status:String, 
                $areaCode:String, 
                $customer:Int, 
                $sales:Int, 
                $projectType:String
            ) {
                GetReportingSales (
                    start_date:$startDate,
                    end_date:$endDate
                    type_detail_id:$typeDetail,
                    item_id:$itemId,
                    status:$status,
                    area_code:$areaCode,
                    customer_id:$customer,
                    sales_id:$sales,
                    project_type:$projectType
                ) {
                    detail {
                        invoice_number
                        invoice_date
                        status
                        purchase_order_number
                        sp_number
                        delivery_order_number
                        sales_id
                        sales_name
                        areacode
                        areaname
                        project_name
                        project_type
                        payment_method
                        customer_id
                        customer_name
                        type_detail_id
                        group_type_name
                        item_group_name
                        item_id
                        product_code
                        item_name
                        quantity
                        uom
                        price
                        discount
                        price_discount
                        deposit_amount
                        tax_amount
                        vat
                        payment_status
                    }
                }
            }
        `;
        return query;
    }

    async getSalesReport(variables) {
        var query = gql`
            query (
                $startDate:String!,
                $endDate:String!, 
                $typeDetail:String, 
                $itemId:String,
                $status:String, 
                $areaCode:String, 
                $customer:Int, 
                $sales:Int, 
                $projectType:String
            ) {
                GetReportingSales(
                    start_date:$startDate,
                    end_date:$endDate
                    type_detail_id:$typeDetail,
                    item_id:$itemId,
                    status:$status,
                    area_code:$areaCode,
                    customer_id:$customer,
                    sales_id:$sales,
                    project_type:$projectType
                ) {
                    summary {
                        quantity_per_status
                        total_invoice_per_status
                        total_invoice_payment_per_status
                    }
                    detail {
                        invoice_number
                        invoice_date
                        status
                        purchase_order_number
                        sp_number
                        delivery_order_number
                        sales_id
                        sales_name
                        areacode
                        areaname
                        project_name
                        project_type
                        payment_method
                        customer_id
                        customer_name
                        type_detail_id
                        group_type_name
                        item_group_name
                        item_id
                        product_code
                        item_name
                        quantity
                        uom
                        price
                        discount
                        price_discount
                        deposit_amount
                        tax_amount
                        vat
                        payment_status
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetReportingSales;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    status: data[i].status,
                    purchase_order_number: data[i].purchase_order_number,
                    sp_number: data[i].sp_number,
                    customer_name: data[i].customer_name,
                    project_type: data[i].project_type,
                    project_name: data[i].project_name,
                    payment_method: data[i].payment_method,
                    invoice_number: data[i].invoice_number,
                    sales_name: data[i].sales_name,
                    product_code: data[i].product_code,
                    group_type_name: data[i].group_type_name,
                    item_name: data[i].item_name,
                    quantity: data[i].quantity,
                    uom: data[i].uom,
                    price_discount: kendo.toString(data[i].price_discount, "n2"),
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    salesReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 40}, //colB
            {width: 40}, //colC
            {width: 40}, //colD
            {width: 60}, //colE
            {width: 20}, //colF
            {width: 40}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 40}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 40}, //colO
            {width: 20}, //colP

        ];
        return size;
    }

    salesReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'REPORT SALES',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO. PO',
            colC : 'NO. SP',
            colD : 'AREA',
            colE : 'NAMA CUSTOMER',
            colF : 'TIPE',
            colG : 'NAMA PROJECT',
            colH : 'METODE PEMBAYARAN',
            colI : 'SALES',
            colJ : 'NO. INVOICE',
            colK : 'JUMLAH',
            colL : 'SATUAN',
            colM : 'KODE BARANG',
            colN : 'TIPE BARANG',
            colO : 'NAMA BARANG',
            colP : 'TOTAL',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].purchase_order_number,
                    colC : data[i].sp_number,
                    colD : data[i].areaname,
                    colE : data[i].customer_name,
                    colF : data[i].project_type,
                    colG : data[i].project_name,
                    colH : data[i].payment_method,
                    colI : data[i].sales_name,
                    colJ : data[i].invoice_number,
                    colK : data[i].quantity,
                    colL : data[i].uom,
                    colM : data[i].product_code,
                    colN : data[i].group_type_name,
                    colO : data[i].item_name,
                    colP : { v: data[i].price_discount, t: 'n' , z: numberFormat },
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetAreaCode;
        var areaData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].areacode, 
                            label: data[i].areacode + ' (' + data[i].areaname + ')'}
                areaData.push(str);
            }
        }
        return areaData
    }

    async getSalesQuery(){
        var query = gql`query{
            GetContact(ContactType:"Employee"){
            contact_id
              contact_name
          }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var salesData = [];
        for (let i = 0; i < data.length; i++) {
            var label = data[i].contact_name
            var str = { value: data[i].contact_id, 
                        label: label}
            salesData.push(str);
        }
        return salesData;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
            contact_id
              contact_name
          }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var distributorData = [];
        for (let i = 0; i < data.length; i++) {
            var label = data[i].contact_name
            var str = { value: data[i].contact_id, 
                        label: label}
            distributorData.push(str);
        }
        return distributorData;
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        
        var typeDetailData = [];
        for (let i = 0; i < result.data.GetTypeDetail.length; i++) {
            var label = result.data.GetTypeDetail[i].type_detail_name;
            var str = {value:result.data.GetTypeDetail[i].type_detail_id, label:label}
            typeDetailData.push(str);
        }
        return typeDetailData
    }
    
    async getItemQuery(variables){
        var query = gql`
            query ($typeDetailId:String) {
                GetProductMaster (typeDetailID:$typeDetailId) {
                    ProductMaster {
                        item_id
                        product_code
                        item_name
                    }
                    Total
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        
        var itemData = [];
        for (let i = 0; i < result.data.GetProductMaster.Total; i++) {
            var label = result.data.GetProductMaster.ProductMaster[i].product_code + ' (' + result.data.GetProductMaster.ProductMaster[i].item_name + ')';
            var str = {
                value: result.data.GetProductMaster.ProductMaster[i].item_id,
                label: label
            }
            itemData.push(str);
        }
        return itemData
    }
}

export default new SalesReportService();