import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readTrasanctionDeliveryOrderReport(){
        var query = `query($startDate:String!,$endDate:String!) {
            GetTransactionDeliveryOrderReport(start_date:$startDate,end_date:$endDate){
                summary{
                    quantity_per_status{
                        new
                        preprare
                        ready
                        finish
                        cancel
                        delete
                        
                    }
                    quantity_per_delivery{
                        new
                        preprare
                        ready
                        finish
                        cancel
                        delete
                    }
                }
                detail{
                    delivery_order_id
                    delivery_order_number
                    delivery_order_date
                    status
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    project_name
                    project_type
                    customer_name
                    attachment
                    created_by
                    item_id
                    item_name
                  	product_code
                    barcode
                    type_detail_id
                    uom
                    ordered_quantity
                    delivered_quantity
                    remaining_quantity
                    quantity
                    notes
                }
            }
        }`;
        return query;
    }

    async getTransactionDeliveryOrderReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!) {
            GetTransactionDeliveryOrderReport(start_date:$startDate,end_date:$endDate){
                summary{
                    quantity_per_status{
                        new
                        preprare
                        ready
                        finish
                        cancel
                        delete
                        
                    }
                    quantity_per_delivery{
                        new
                        preprare
                        ready
                        finish
                        cancel
                        delete
                    }
                }
                detail{
                    delivery_order_id
                    delivery_order_number
                    delivery_order_date
                    status
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    project_name
                    customer_name
                    attachment
                    created_by
                    item_id
                    item_name
                  	product_code
                    barcode
                    type_detail_id
                    uom
                    ordered_quantity
                    delivered_quantity
                    remaining_quantity
                    quantity
                    notes
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetTransactionDeliveryOrderReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    status: data[i].status,
                    delivery_order_number: data[i].delivery_order_number,
                    notes: data[i].notes,
                    delivery_order_date: kendo.toString(kendo.parseDate(data[i].delivery_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    sp_number: data[i].sp_number,
                    purchase_order_number: data[i].purchase_order_number,
                    customer_name: data[i].customer_name,
                    project_name: data[i].project_name,
                    created_by: data[i].created_by,                    
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    quantity: data[i].quantity,
                    uom: data[i].uom,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    transactionDeliveryOrderReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS
            {width: 20}, //colT
            {width: 20}, //colU
            {width: 20}, //colV
            {width: 20}, //colW
            {width: 20}, //colX
            {width: 20}, //colY
            {width: 20}, //colZ
            {width: 20}, //colAA
            {width: 20}, //colAB
            {width: 20}, //colAC

        ];
        return size;
    }

    transactionDeliveryOrderReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'TRANSAKSI DELIVERY ORDER',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {colA : 'STATUS',
                    colB : 'NO. DO',
                    colC : 'CATATAN',
                    colD : 'TANGGAL',
                    colE : 'NO. SP',
                    colF : 'NO. PO',
                    colG : 'CUSTOMER',
                    colH : 'PROJECT',
                    colI : 'ATTACHMENT',
                    colJ : 'DIBUAT OLEH',
                    colK : 'JUMLAH',
                    colL : 'SATUAN',
                    colM : 'KODE BARANG',
                    colN : 'NAMA ITEM',
                };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].delivery_order_number,
                    colC : data[i].notes,
                    colD : (data[i].delivery_order_date == null || data[i].delivery_order_date == '') ? '-' : { v: data[i].delivery_order_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colE : data[i].sp_number,
                    colF : data[i].purchase_order_number,
                    colG : data[i].customer_name,
                    colH : data[i].project_name,
                    colI : data[i].attachment,
                    colJ : data[i].created_by,
                    colK : data[i].quantity,
                    colL : data[i].uom,
                    colM : data[i].product_code,
                    colN : data[i].item_name,
                    // colO : { v: data[i].quantity, t: 'n' , z: numberFormat 
                };
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }
}

export default new PurchasingInvoiceReportService();