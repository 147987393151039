import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readTrasanctionPurchaseOrderReport(){
        var query = `query($startDate:String!, $endDate:String!, $typeDetail:String, $status:String, $areaCode:String, $customer:Int, $sales:Int, $projectType:Int) {
            GetTransactionPurchaseOrderReport(
                start_date:$startDate,
                end_date:$endDate
                type_detail_id:$typeDetail,
                status:$status,
                area_code:$areaCode,
                customer_id:$customer,
                sales_id:$sales,
                project_type:$projectType
            ){
                summary {
                    quantity_per_status {
                        new
                        revisi
                        checking
                        approved
                        process
                        delete
                        finish
                    }
                    total_price_per_status {
                        new
                        revisi
                        checking
                        approved
                        process
                        delete
                        finish
                    }
                }
                detail{
                    areacode
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    purchase_order_date
                    status
                    project_id
                    project_name
                    project_type
                    payment_method
                    sales_id
                    sales_name
                    customer_id
                    customer_name
                    term_of_payment
                    storage_id
                    price_category_id
                    purchase_order_sequence
                    purchase_order_type
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    sample
                    total
                    on_going_purchase_order_total_by_project_id
                    invoice_number
                    delivery_order_number
                    created_by
                    item_id
                    product_code
                    barcode
                    type_detail_id
                    item_name
                    uom
                    base_uom
                    conv_amount
                    price_per_unit
                    price_project
                    price_retail
                    price
                    vat_per_unit
                    vat
                    quantity
                    quantity_base
                    discount1
                    discount2
                    discount3
                    discount4
                    discount5
                    discount_cash
                    discount_amount_1
                    discount_amount_2
                    discount_amount_3
                    discount_amount_4
                    discount_amount_5
                    discount_amount_cash
                    created_at
                    price_discount
                    price_discount_per_unit
                }
            }
        }`;
        return query;
    }

    async getTransactionPurchaseOrderReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!, $typeDetail:String, $status:String, $areaCode:String, $customer:Int, $sales:Int, $projectType:Int) {
            GetTransactionPurchaseOrderReport(
                start_date:$startDate,
                end_date:$endDate
                type_detail_id:$typeDetail,
                status:$status,
                area_code:$areaCode,
                customer_id:$customer,
                sales_id:$sales,
                project_type:$projectType
            ){
                summary {
                    quantity_per_status {
                        new
                        revisi
                        checking
                        approved
                        process
                        delete
                        finish
                    }
                    total_price_per_status {
                        new
                        revisi
                        checking
                        approved
                        process
                        delete
                        finish
                    }
                }
                detail{
                    areacode
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    purchase_order_date
                    status
                    project_id
                    project_name
                    project_type
                    payment_method
                    sales_id
                    sales_name
                    customer_id
                    customer_name
                    term_of_payment
                    storage_id
                    price_category_id
                    purchase_order_sequence
                    purchase_order_type
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    sample
                    total
                    on_going_purchase_order_total_by_project_id
                    invoice_number
                    delivery_order_number
                    created_by
                    item_id
                    product_code
                    barcode
                    type_detail_id
                    item_name
                    uom
                    base_uom
                    conv_amount
                    price_per_unit
                    price_project
                    price_retail
                    price
                    vat_per_unit
                    vat
                    quantity
                    quantity_base
                    discount1
                    discount2
                    discount3
                    discount4
                    discount5
                    discount_cash
                    discount_amount_1
                    discount_amount_2
                    discount_amount_3
                    discount_amount_4
                    discount_amount_5
                    discount_amount_cash
                    created_at
                    price_discount
                    price_discount_per_unit
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetTransactionPurchaseOrderReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    status: data[i].status,
                    purchase_order_number: data[i].purchase_order_number,
                    purchase_order_date: kendo.toString(kendo.parseDate(data[i].purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    created_at: kendo.toString(kendo.parseDate(data[i].created_at.toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    sp_number: data[i].sp_number,
                    customer_name: data[i].customer_name,
                    total: kendo.toString(data[i].price_discount, "n2"),
                    purchase_order_type: data[i].purchase_order_type,
                    project_name: data[i].project_name,
                    payment_method: data[i].payment_method,
                    jatuh_tempo: kendo.parseInt(data[i].term_of_payment),
                    notes: data[i].notes,
                    created_by: data[i].created_by,
                    sales_name: data[i].sales_name,
                    quantity: data[i].quantity,
                    uom: data[i].uom,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    notes: data[i].notes,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    transactionPurchaseOrderReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS
            {width: 20}, //colT
            {width: 20}, //colU
            {width: 20}, //colV
            {width: 20}, //colW
            {width: 20}, //colX
            {width: 20}, //colY
            {width: 20}, //colZ
            {width: 20}, //colAA
            {width: 20}, //colAB
            {width: 20}, //colAC

        ];
        return size;
    }

    transactionPurchaseOrderReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'TRANSAKSI PURCHASE ORDER',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {colA : 'STATUS',
                    colB : 'NO. PO',
                    colC : 'TANGGAL PO',
                    colD : 'TANGGAL BUAT',
                    colE : 'NO. SP',
                    colF : 'NAMA CUSTOMER',
                    colG : 'TOTAL',
                    colH : 'TIPE',
                    colI : 'NAMA PROJECT',
                    colJ : 'METODE PEMBAYARAN',
                    colK : 'JATUH TEMPO',
                    colL : 'CATATAN',
                    colM : 'ATTACHMENT',
                    colN : 'CREATED BY',
                    colO : 'SALES',
                    colP : 'NO. DO',
                    colQ : 'NO. INVOICE',
                    colR : 'JUMLAH',
                    colS : 'SATUAN',
                    colT : 'KODE BARANG',
                    colU : 'NAMA BARANG'
                };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].purchase_order_number,
                    colC : (data[i].purchase_order_date == null || data[i].purchase_order_date == '') ? '-' : { v: data[i].purchase_order_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colD : (data[i].created_at == null || data[i].created_at == '') ? '-' : { v: new Date(data[i].created_at.toString()).toISOString().split('T')[0], t: 'd', z: 'DD-MMM-YYYY' },
                    colE : data[i].sp_number,
                    colF : data[i].customer_name,
                    colG : { v: data[i].price_discount, t: 'n' , z: numberFormat },
                    colH : data[i].purchase_order_type,
                    colI : data[i].project_name,
                    colJ : data[i].payment_method,
                    colK : data[i].term_of_payment,
                    colL : data[i].notes,
                    colM : data[i].attachment,
                    colN : data[i].created_by,
                    colO : data[i].sales_name,
                    colP : data[i].down_payment,
                    colQ : data[i].invoice_number,
                    colR : data[i].quantity,
                    colS : data[i].uom,
                    colT : data[i].product_code,
                    colU : data[i].item_name
                    
                    // colO : { v: data[i].quantity, t: 'n' , z: numberFormat 
                };
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetAreaCode;
        var areaData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].areacode, 
                            label: data[i].areacode + ' (' + data[i].areaname + ')'}
                areaData.push(str);
            }
        }
        return areaData
    }

    async getSalesQuery(){
        var query = gql`query{
            GetContact(ContactType:"Employee"){
            contact_id
              contact_name
          }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var salesData = [];
        for (let i = 0; i < data.length; i++) {
            var label = data[i].contact_name
            var str = { value: data[i].contact_id, 
                        label: label}
            salesData.push(str);
        }
        return salesData;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
            contact_id
              contact_name
          }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var distributorData = [];
        for (let i = 0; i < data.length; i++) {
            var label = data[i].contact_name
            var str = { value: data[i].contact_id, 
                        label: label}
            distributorData.push(str);
        }
        return distributorData;
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        
        var typeDetailData = [];
        for (let i = 0; i < result.data.GetTypeDetail.length; i++) {
            var label = result.data.GetTypeDetail[i].type_detail_name;
            var str = {value:result.data.GetTypeDetail[i].type_detail_id, label:label}
            typeDetailData.push(str);
        }
        return typeDetailData
    }
}

export default new PurchasingInvoiceReportService();